/**
 * Get the user's location using the Geolocation API
 * @returns A promise that resolves with the user's location
 * if the Geolocation API is available in the user's browser
 * and the user grants permission to access their location.
 * The promise resolves with undefined if the Geolocation API
 * is not available or if there's an error retrieving the location.
 * @example
 * const userLocation = await getUserLocation();
 * if (userLocation) {
 *  console.log(userLocation.coords.latitude, userLocation.coords.longitude);
 * }
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Geolocation_API
 */

export const getUserLocation = (): Promise<GeolocationPosition | undefined> => {
  return new Promise(resolve => {
    // Check if Geolocation API is available in the user's browser
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          // Resolve the promise with the user's position on success
          resolve(position);
        },
        () => {
          // Resolve with undefined if there's an error retrieving the location
          resolve(undefined);
        },
      );
    } else {
      // Resolve with undefined if Geolocation API is not available
      resolve(undefined);
    }
  });
};
