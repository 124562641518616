import { QueryKey } from 'react-query';
import { GetPacketsQueryParams } from 'shared-types';

enum DocumentSignatureQueryKeys {
  POLICY_AGREEMENTS = 'policy_agreements',
  GET_AGREEMENTS = 'get_agreements',
  AGREEMENT_BY_ID = 'agreement_by_id',
  AGREEMENTS_SIGNERS = 'agreements_signers',
}

export const makePolicyAgreementsToSignKeys = (officeId: number): QueryKey => {
  return [DocumentSignatureQueryKeys.POLICY_AGREEMENTS, officeId];
};

export const makeGetAgreementByIdKeys = (officeId: number, packetId?: string): QueryKey => {
  return [DocumentSignatureQueryKeys.AGREEMENT_BY_ID, officeId, packetId];
};

export const makeGetAgreementsKey = (
  officeId: number,
  filters?: GetPacketsQueryParams,
): QueryKey => {
  return [DocumentSignatureQueryKeys.GET_AGREEMENTS, officeId, filters].filter(Boolean);
};

export const makeAgreementsSignersKeys = (officeId: number, packetId: number): QueryKey => {
  return [DocumentSignatureQueryKeys.AGREEMENTS_SIGNERS, officeId, packetId];
};
