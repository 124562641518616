import { useAuth, useUser } from '@clerk/clerk-react';
import ROUTES from 'pages/routes-enum';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useShouldChangePassword = () => {
  const history = useHistory();
  const { isLoaded, isSignedIn } = useAuth();
  const { user: clerkUser } = useUser();

  useEffect(() => {
    if (!isLoaded || !isSignedIn) return;

    if (clerkUser?.publicMetadata?.shouldChangePassword) {
      history.push(ROUTES.USERNAME_CHANGE_PASSWORD);
    }
  }, [isLoaded, isSignedIn, clerkUser, history]);
};

export default useShouldChangePassword;
