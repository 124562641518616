import { Location } from 'history';
import ROUTES from 'pages/routes-enum';

/**
 * Generates a query string from the given query parameter and parameters.
 *
 * @param {URLSearchParams | string} queryParam - The query parameter to generate the query string from.
 * @param {Record<string, unknown>} [params] - The additional parameters to include in the query string.
 * @return {string} - The generated query string.
 */
export function generateQueryParams(
  queryParam: URLSearchParams | string,
  params?: Record<string, unknown>,
) {
  const entries = Object.entries(params ?? {});

  const query = typeof queryParam === 'string' ? new URLSearchParams(queryParam) : queryParam;

  entries.forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      query.set(key, String(value));
    }
  });

  return query.toString();
}

export function preserveQueryParams(route: string) {
  const [url, searchParams] = route.split('?');

  return {
    pathname: url,
    search: generateQueryParams(
      `${window.location.search}${searchParams ? `&${searchParams}` : ''}`,
    ),
  };
}

export const isInIframe = window && window.location !== window.parent.location;

export const chooseSignRoute = () => (isInIframe ? ROUTES.SSO_ERROR : ROUTES.SIGNIN);

/**
 * Removes the given additional parameters from the query string.
 *
 * @param {Location} location - The location object to remove the additional parameters from.
 * @param {string[]} [additionalParams=[]] - The additional parameters to remove from the query string.
 * @return {string} - The updated query string.
 */
export function removeQueryParams(location: Location, additionalParams: string[] = []): string {
  const queryParams = new URLSearchParams(location.search);

  additionalParams.forEach(param => {
    queryParams.delete(param);
  });

  return queryParams.toString();
}
