import EVENTS from 'analytics/events';
import { useAnalytics } from 'analytics/hooks';
import { AgreementsBanner } from 'components/AgreementsBanner';
import { ImpersonationHeader } from 'components/ImpersonationHeader';
import PrivateRoute from 'components/PrivateRoute';
import MenuLeft from 'components/PrivateRoute/MenuLeft';
import { useLogoutByInactivity } from 'hooks/useLogoutByInactivity';
import { useUpdateTheme } from 'hooks/useUpdateTheme';
import i18next from 'i18next';
import { fetched, preferencesLoaded } from 'modules/user';
import React, { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch } from 'react-router';
import { useGetUser } from 'service/user';
import { UsersClaims } from 'shared-types/user';
import styled from 'styled-components';
import { useGetClaims } from 'utils/claim';
import { isDesktop } from 'utils/responsivity';
import useShouldChangePassword from './OidcLogin/hooks/useShouldChangePassword';
import Loading from './SSO/animations/Loading';
import { useSsoPref } from './SSO/hooks';
import history from './history';
import ROUTES from './routes-enum';

const CreditorReports = React.lazy(() => import('./CreditorReports'));
const NewOfficeReports = React.lazy(() => import('./NewOfficeReports'));
const MonthlyManagementReports = React.lazy(() => import('./Dashboards/MonthlyManagementReports'));
const Payments = React.lazy(() => import('./Payments'));
const BackOffice = React.lazy(() => import('./Backoffice'));
const TeamsDashboard = React.lazy(() => import('./Dashboards/TeamsDashboard'));
const CustomReports = React.lazy(() => import('./Dashboards/CustomReports'));
const CustomAdjustments = React.lazy(() => import('./AdjustmentRequest'));
const VariablesRouter = React.lazy(() => import('./Variables/router'));
const DataSourcePage = React.lazy(() => import('./DataSources/pages/[tab-id]'));
const SelectedPlan = React.lazy(() => import('./CommisionPlan/pages/SelectedPlan'));
const CommissionPlanList = React.lazy(() => import('./CommisionPlan/pages/CommissionPlanList'));
const SheetSync = React.lazy(() => import('./SheetSync'));
const Docs = React.lazy(() => import('./Docs'));
const ProfilesRouter = React.lazy(() => import('./Users/ManageProfiles'));
const UserRouter = React.lazy(() => import('./Users/ManageUsers'));
const ManageOffice = React.lazy(() => import('./Users/ManageOffice'));
const RootRouter = React.lazy(() => import('./Commission/router'));
const CompaniesRouter = React.lazy(() => import('./Companies'));
const Customers = React.lazy(() => import('./Users/Customers'));
const BulkUserCreate = React.lazy(() => import('./Users/Customers/BulkUserCreate'));
const PaymentGroupsRouter = React.lazy(() => import('./PaymentGroups'));
const ClosureRouter = React.lazy(() => import('./Closure'));
const InquiriesRouter = React.lazy(() => import('./Inquiries'));
const JobsRouter = React.lazy(() => import('./Jobs'));
const AgreementsManagement = React.lazy(() => import('./Agreements/AgreementsManagement'));
const OutboundWebhookList = React.lazy(() => import('./OutboundWebhooks'));

const PlanStatementPanelPage = React.lazy(
  () => import('./StatementPanel/pages/PlanStatementPanel'),
);
const InternalStatementPanelPage = React.lazy(
  () => import('./StatementPanel/pages/InternalStatementPanel'),
);

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  position: fixed;
  z-index: 100000;
`;

export default function PrivateRouteContainer() {
  const desktop = isDesktop();
  const dispatch = useDispatch();

  const { data: user } = useGetUser();
  const { findAndUseClaim } = useGetClaims();
  const { ssoHideMenu } = useSsoPref();
  useLogoutByInactivity();

  useUpdateTheme();
  useShouldChangePassword();

  const { track, setScreen } = useAnalytics();

  useEffect(() => {
    const unlisten = history.listen(location => {
      setScreen(location.pathname);
      track(EVENTS.PAGE_VIEW, {
        host: window.location.host,
        path: location.pathname,
        query: location.search,
      });
    });
    return unlisten;
  }, [setScreen, track]);

  useEffect(() => {
    async function setClarityConfigs() {
      if (user?.credor?.email) {
        // @ts-ignore
        await window?.clarity?.('identify', user.credor.email);
        // @ts-ignore
        await window?.clarity?.('set', 'email', user.credor.email);

        if (user.escritorio?.id_escritorio)
          // @ts-ignore
          await window?.clarity?.('set', 'office_id', user.escritorio?.id_escritorio);

        if (user.escritorio?.nome_escritorio)
          // @ts-ignore
          await window?.clarity?.('set', 'office_name', user.escritorio?.nome_escritorio);
      }
    }

    setClarityConfigs();

    if (user) {
      dispatch(fetched(user));
      const userPreferences = findAndUseClaim(UsersClaims.USER_PREFERENCES).data;
      if (userPreferences) {
        dispatch(preferencesLoaded(userPreferences));
      }

      const language = findAndUseClaim(UsersClaims.LANGUAGE_PREFERENCE)?.data;
      i18next.changeLanguage(language ?? navigator.language);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div>
      <StyledBannerContainer>
        <ImpersonationHeader />
        <AgreementsBanner />
      </StyledBannerContainer>
      {desktop && !ssoHideMenu && <MenuLeft />}

      <Wrapper>
        <Suspense fallback={<Loading />}>
          <Switch>
            <PrivateRoute path={ROUTES.SHEET_SYNC} component={SheetSync} />
            <PrivateRoute path={ROUTES.DOCS} component={Docs} />
            <PrivateRoute path={ROUTES.DEFAULT_PROFILE} component={ManageOffice} />
            <PrivateRoute path={ROUTES.PROFILES} component={ProfilesRouter} />
            <PrivateRoute
              exact
              path={ROUTES.BULK_USER_CREATE}
              component={BulkUserCreate}
              claimsNeeded={[UsersClaims.USER_CREATE]}
            />
            <PrivateRoute path={ROUTES.CUSTOMERS} component={Customers} />
            <PrivateRoute
              path={ROUTES.USERS}
              component={UserRouter}
              claimsNeeded={[UsersClaims.USER_MANAGEMENT, UsersClaims.USER_CREATE]}
            />

            <PrivateRoute path={ROUTES.VARIABLES} component={VariablesRouter} />
            <PrivateRoute
              exact
              path={[ROUTES.DATA_SOURCES, ROUTES.DATA_SOURCES_TAB]}
              component={DataSourcePage}
            />

            <PrivateRoute component={CommissionPlanList} exact path={ROUTES.COMMISSION_PLAN_LIST} />
            <PrivateRoute
              component={SelectedPlan}
              path={[ROUTES.SELECTED_TAB_COMMISSION_PLAN, ROUTES.SELECTED_COMISSION_PLAN]}
            />

            <PrivateRoute
              path={ROUTES.NEW_OFFICE_REPORTS}
              component={NewOfficeReports}
              claimsNeeded={[UsersClaims.OFFICE_CREDITORS_VIEW]}
            />
            <PrivateRoute
              path={ROUTES.MONTHLY_MANAGEMENT_REPORTS}
              component={MonthlyManagementReports}
              claimsNeeded={[UsersClaims.OFFICE_CREDITORS_VIEW]}
            />
            <PrivateRoute path={ROUTES.TEAMS_REPORTS} component={TeamsDashboard} />
            <PrivateRoute path={ROUTES.CREDITOR_REPORT} component={CreditorReports} />

            <PrivateRoute path={ROUTES.CLOSURE_ROOT} component={ClosureRouter} />

            <PrivateRoute path={ROUTES.CUSTOM_REPORTS} component={CustomReports} />

            <PrivateRoute
              path={ROUTES.ADJUSTMENTS_REQUEST}
              component={CustomAdjustments}
              claimsNeeded={[UsersClaims.ADJUSTMENT_REQUEST_VIEW, UsersClaims.TEAM_CREDITORS_VIEW]}
            />

            <PrivateRoute
              path={ROUTES.ADJUSTMENTS_ADMIN}
              component={CustomAdjustments}
              claimsNeeded={[UsersClaims.CLOSURE_UPDATE, UsersClaims.ADJUSTMENT_REQUEST_VIEW]}
            />
            <PrivateRoute path={ROUTES.PAYMENTS_ROOT} component={Payments} />
            <PrivateRoute path={ROUTES.OUTBOUND_WEBHOOKS_LIST} component={OutboundWebhookList} />
            <PrivateRoute path={ROUTES.BACKOFFICE_ROOT} component={BackOffice} />
            <PrivateRoute
              path={ROUTES.COMPANIES}
              component={CompaniesRouter}
              claimsNeeded={[UsersClaims.COMPANY_MANAGEMENT]}
            />
            <PrivateRoute path={ROUTES.PAYMENT_GROUPS} component={PaymentGroupsRouter} />
            <PrivateRoute path={ROUTES.INQUIRIES} component={InquiriesRouter} />
            <PrivateRoute
              path={ROUTES.JOBS}
              component={JobsRouter}
              claimsNeeded={[UsersClaims.GENERIC_DATA_IMPORT]}
            />
            <PrivateRoute path={ROUTES.STATEMENT_PANEL_PLAN} component={PlanStatementPanelPage} />
            <PrivateRoute
              path={ROUTES.INTERNAL_STATEMENT_PANEL_PLAN}
              component={InternalStatementPanelPage}
            />
            <PrivateRoute
              path={ROUTES.AGREEMENTS}
              component={AgreementsManagement}
              claimsNeeded={[UsersClaims.POLICY_AGREEMENT_MODULE]}
            />
            <PrivateRoute path={ROUTES.ROOT} component={RootRouter} />
          </Switch>
        </Suspense>
      </Wrapper>
    </div>
  );
}
