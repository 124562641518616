enum ROUTES {
  LOGIN = '/login',
  DOCS = '/docs',
  OIDC_LOGIN = '/oidc-login',
  ONBOARDING = '/onboarding',
  SOCIAL_LOGIN = '/social-login',
  SSO = '/sso',
  SSO_ERROR = '/sso/error',
  SIGNIN = '/signin',
  USERNAME_SIGNIN = '/login/usuario',
  USERNAME_CHANGE_PASSWORD = '/usuario/alterar-senha',
  SLUG_SIGNIN = '/signin/:slug',
  REFRESH_USER = '/refresh-user',
  CUSTOM_REPORTS = '/relatorio/customizado',
  CUSTOM_REPORTS_DETAILS = '/relatorio/customizado/:questionId',
  LOGOUT = '/logout',
  CALLBACK = '/callback',
  CALLBACK_AUTH = '/callback/auth',
  LOGIN_ERROR = '/erro-ao-entrar',
  NEW_OFFICE_REPORTS = '/novo-relatorio',
  MONTHLY_MANAGEMENT_REPORTS = '/gerencial-mensal',
  TEAMS_REPORTS = '/relatorio/times',
  CREDITOR_REPORT = '/relatorio-assessor',
  SHEET_SYNC = '/google-sheets',
  ROOT = '/',
  ROOT_HOME = '/home',
  ROOT_FILES = '/arquivos',
  CUSTOMERS = '/clientes',
  BULK_USER_CREATE = '/clientes/criar',
  PAYMENT_INFO = '/pagamentos/informacoes-de-pagamento',
  USERS = '/permissoes/usuarios',
  USER_CREATE = '/permissoes/usuarios/novo',
  USER_EDIT = '/permissoes/usuarios/:externalCreditorId',
  PROFILES = '/permissoes/perfis-de-acesso',
  BACKOFFICE_PROFILE_CREATE = '/backoffice/perfis-de-acesso/novo',
  BACKOFFICE_PROFILE_EDIT = '/backoffice/perfis-de-acesso/editar/:id',
  PROFILES_CREATE = '/permissoes/perfis-de-acesso/novo',
  PROFILES_EDIT = '/permissoes/perfis-de-acesso/editar/:id',
  DEFAULT_PROFILE = '/permissoes/perfil-padrão',
  ROA = '/return-on-asset',
  BACKOFFICE_ROOT = '/backoffice',
  USER_IMPERSONATION = '/user-impersonation',
  CLOSURE_ROOT = '/fechamentos',
  CLOSURE_DRAWER = '/fechamentos/Drawer',
  SELECTED_CLOSURE_ROOT = '/fechamentos/:closureDate',
  CLOSURE_SHEETS = '/fechamentos/:closureDate/configuracao/gerenciar-planilhas',
  NEW_CLOSURE_SHEET_ORIGIN_SELECTION = '/fechamentos/:closureDate/configuracao/gerenciar-planilhas/nova',
  NEW_CLOSURE_SHEET_ADDITIONAL_DATA = '/fechamentos/:closureDate/configuracao/gerenciar-planilhas/nova/adicionais',
  NEW_CLOSURE_SHEET_FILE_UPLOAD = '/fechamentos/:closureDate/configuracao/gerenciar-planilhas/nova/upload',
  NEW_CLOSURE_SHEET_CHECKOUT = '/fechamentos/:closureDate/configuracao/gerenciar-planilhas/nova/checkout',
  NEW_CLOSURE_SHEET_SUCCESS = '/fechamentos/:closureDate/sucesso',
  CLOSURE_ASSURANCE = '/fechamentos/:closureDate/conferencia',
  CLOSURE_CONFIG_ROOT = '/fechamentos/:closureDate/configuracao',
  CLOSURE_OPEN = '/fechamentos/:closureDate/liberar',
  CLOSURE_RULES = '/fechamentos/:closureDate/configuracao/regras',
  CLOSURE_ADJUSTMENTS = '/fechamentos/:closureDate/configuracao/ajustes',
  CLOSURE_TAX = '/fechamentos/:closureDate/configuracao/impostos',
  CLOSURE_ADJUSTMENTS_REQUEST = '/fechamentos/:closureDate/configuracao/pedidos-de-ajustes',
  CLOSURE_SALARY = '/fechamentos/:closureDate/configuracao/renda-recorrente',
  CLOSURE_GOALS = '/fechamentos/:closureDate/configuracao/metas',
  CLOSURE_NEW_GOAL = '/fechamentos/:closureDate/configuracao/metas/criar',
  CLOSURE_EDIT_GOAL = '/fechamentos/:closureDate/configuracao/metas/editar/:id',
  CLOSURE_LISTS = '/fechamentos/:closureDate/configuracao/listas',
  CLOSURE_REPLACEMENTS = '/fechamentos/:closureDate/configuracao/trocar-campos',
  CLOSURE_BACKUP = '/fechamentos/:closureDate/configuracao/backup',
  CLOSURE_VARIABLES = '/fechamentos/:closureDate/configuracao/variaveis',
  CLOSURE_TEAMS = '/fechamentos/:closureDate/configuracao/times',
  ADJUSTMENTS_REQUEST = '/ajustes/lider',
  ADJUSTMENTS_ADMIN = '/regras/configurar/pedidos-de-ajustes',
  DATA_SOURCES = '/fonte-de-dados',
  DATA_SOURCES_TAB = '/fonte-de-dados/tab/:tabId',
  VARIABLES = '/variaveis',
  VARIABLES_TYPE = '/variaveis/:type',
  VARIABLES_TAB = '/variaveis/:type/:tabId',
  PAYMENT_V2 = '/pagamentos/folha-de-pagamento/v2',
  PAYMENT = '/pagamentos/folha-de-pagamento',
  PAYMENT_SELECTED = '/pagamentos/folha-de-pagamento/:closureDate',
  PAYMENT_EXCERPT = '/pagamentos/folha-de-pagamento/:closureDate/extrato',
  COMPANIES = '/empresas',
  NEW_COMPANY = '/empresas/novo',
  EDIT_COMPANY = '/empresas/:id',
  PAYMENT_GROUPS = '/grupos-de-pagamento',
  NEW_PAYMENT_GROUP = '/grupos-de-pagamento/novo',
  PAYMENTS_ROOT = '/pagamentos',
  DOCUMENTS = '/pagamentos/documentos',
  DOCUMENTS_FILES = '/pagamentos/documentos/arquivos',
  COMMISSION_PLAN_LIST = '/planos',
  SELECTED_COMISSION_PLAN = '/planos/:planId',
  SELECTED_TAB_COMMISSION_PLAN = '/planos/:planId/tab/:tabId',
  INQUIRIES = '/chamados',
  STATEMENT_PANEL_PLAN = '/extrato/plano/:groupId',
  INTERNAL_STATEMENT_PANEL_PLAN = '/interno/extrato/plano/:planId',
  JOBS = '/sincronizacoes',
  PAYMENT_OPTIONS = '/pagamentos/opcoes-de-pagamento',
  CREATE_PAYMENT_OPTION = '/pagamentos/opcoes-de-pagamento/novo',
  EDIT_PAYMENT_OPTION = '/pagamentos/opcoes-de-pagamento/editar/:id',
  AGREEMENTS = '/politicas',
  OUTBOUND_WEBHOOKS_LIST = '/integracoes-saida',
  OUTBOUND_WEBHOOKS_CONFIG = '/integracoes-saida/:id',
}
export default ROUTES;
